'use client'

import React, { ReactNode, useEffect, useRef, useState } from "react"
import { closeAlldropdowns } from "@/services/theme"
import { toggleArrayElement } from "@/services/utils"
import Dropdown from "./Dropdown"
import noUiSlider from 'nouislider'


type AreaProps = {
    value: any[]
    onChange: Function
}

type City = {
    nom: string,
    codeDepartement: string,
    centre: {
        coordinates: number[]
    }
}

export default function Area({
    value,
    onChange
}: AreaProps) {

    const ref = useRef<HTMLDivElement>(null)
    const refButton = useRef<HTMLButtonElement>(null)
    const refTitle = useRef<HTMLElement>(null)
    const refDropdown = useRef<HTMLDivElement>(null)

    const refPreviousValue = useRef<any[]>([])

    const [search, setSearch] = useState<string>('')
    const [selectedCity, setSelectedCity] = useState<City>(value.length > 4 ? {
        nom: value[3],
        codeDepartement: value[4] ?? '',
        centre: {
            coordinates: [value[1], value[0]]
        }
    } : null)
    const [suggestions, setSuggestions] = useState<City[]>([])
    const [distance, setDistance] = useState<number>(value.length > 2 ? value[2] : 0)

    useEffect(() => {
        if (selectedCity !== null) {
            return
        }
        searchCities()
    }, [search])

    useEffect(() => {
        if (refPreviousValue.current.join(',') === value.join(',')) {
            return
        }
        refPreviousValue.current = value
        setSelectedCity(value.length > 4 ? {
            nom: value[3],
            codeDepartement: value[4] ?? '',
            centre: {
                coordinates: [value[1], value[0]]
            }
        } : null)
        setDistance(value.length > 2 ? value[2] : 0)
    }, [value])

    async function searchCities() {
        /*if (search.trim().length === 0) {
            setSuggestions([{
                nom: 'Paris',
                codeDepartement: '75',
                centre: {
                    coordinates: [48.8589, 2.347]
                }
            }, {
                nom: 'Marseille',
                codeDepartement: '13',
                centre: {
                    coordinates: [43.2803, 5.3806]
                }
            }, {
                nom: 'Lyon',
                codeDepartement: '69',
                centre: {
                    coordinates: [45.758, 4.8351]
                }
            }, {
                nom: 'Toulouse',
                codeDepartement: '31',
                centre: {
                    coordinates: [43.6007, 1.4328]
                }
            }])
            console.log('if trim 0')
            return
        }*/
        console.log('search')
        const results = await fetch('https://geo.api.gouv.fr/communes?nom=' + search + '&fields=nom,code,codeDepartement,centre&boost=population&limit=5')
        setSuggestions(await results.json())
    }

    useEffect(() => {
        if (selectedCity === null) {
            onChange(null)
            return
        }
        onChange([
            selectedCity.centre.coordinates[1],
            selectedCity.centre.coordinates[0],
            distance,
            selectedCity.nom,
            selectedCity.codeDepartement
        ])
    }, [selectedCity, distance])

    console.log('selectedCity', { ...selectedCity }, distance)

    return (
        <>
            <div ref={ref} className="select js-multiple-select js-liveSearch">
                <h4 className="text-15 fw-500 ls-2 lh-16">Localisation</h4>
                <button ref={refButton} className="select__button js-button" onClick={() => {
                    console.log('click button')
                    if (refDropdown.current.classList.contains("-is-visible")) {
                        refDropdown.current.classList.remove("-is-visible");
                    } else {
                        closeAlldropdowns();
                        refDropdown.current.classList.add("-is-visible");
                    }
                    (ref.current.querySelector(".js-search") as HTMLInputElement)?.focus();
                }}>
                    <span className="js-button-title">
                        <i className="fi fi-rr-marker me-1" /> {selectedCity ?
                            <>{selectedCity.nom} {selectedCity.codeDepartement && <>({selectedCity.codeDepartement})</>}{distance > 0 ? <> - {distance} km</> : <></>}</>
                            :
                            <>Toute la France</>
                        }
                    </span>
                    <i className="fi fi-bs-angle-down text-primary-3" />
                </button>
                <div ref={refDropdown} className="select__dropdown js-dropdown">
                    {selectedCity ?
                        <>
                            <div className="select__options js-options">
                                <div className="select__options__button d-flex justify-content-between" data-value="paris">
                                    <span className="js-target-title">
                                        <i className="fi fi-rr-marker me-1" /> {selectedCity.nom} {selectedCity.codeDepartement && <>({selectedCity.codeDepartement})</>}
                                    </span>
                                    <a href='#' onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        setSelectedCity(null)
                                    }}><i className="fi fi-ss-circle-xmark mr-5" /></a>
                                </div>
                            </div>
                            <DistanceSlider
                                distance={distance}
                                setDistance={setDistance}
                            />
                        </>
                        :
                        <>
                            <input type="text" placeholder="Rechercher une ville" className="select__search js-search" value={search} onChange={(e) => {
                                setSearch(e.target.value)
                            }} />
                            <div className="select__options js-options">
                                <div className="select__options__button d-flex justify-content-between" data-value='Autour de moi' onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    navigator.geolocation.getCurrentPosition((pos) => {
                                        console.log('geolocation pos', pos)
                                        setSelectedCity({
                                            nom: 'Autour de moi',
                                            codeDepartement: '',
                                            centre: {
                                                coordinates: [pos.coords.longitude, pos.coords.latitude]
                                            }
                                        })
                                    }, (e) => {
                                        console.log('geolocation error', e)
                                        setSelectedCity({
                                            nom: 'Autour de moi',
                                            codeDepartement: '',
                                            centre: {
                                                coordinates: [48.8589, 2.347]
                                            }
                                        })
                                    })
                                }}>
                                    <div>
                                        <i className="fi fi-rr-location-crosshairs" /> Autour de moi
                                    </div>
                                </div>
                                {suggestions.map((suggestion, indexSuggestion) => {
                                    return (
                                        <React.Fragment key={'suggestion-' + indexSuggestion}>
                                            <div className="select__options__button d-flex justify-content-between" data-value={suggestion.nom + ' (' + suggestion.codeDepartement + ')'} onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                setSelectedCity(suggestion)
                                            }}>
                                                <div>{suggestion.nom + ' (' + suggestion.codeDepartement + ')'}</div>
                                            </div>
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                        </>
                    }
                </div>
            </div >
        </>
    )
}

type DistanceSliderProps = {
    distance: number,
    setDistance: Function
}

function DistanceSlider({
    distance,
    setDistance
}: DistanceSliderProps) {

    const ref = useRef()
    const refSlider = useRef<HTMLElement>()

    useEffect(() => {
        refSlider.current = (ref.current as HTMLElement).querySelector(".js-slider");

        if (!refSlider.current) return

        if ('noUiSlider' in refSlider.current) return

        noUiSlider.create((refSlider.current as HTMLElement), {
            start: distance,
            step: 50,
            connect: [true, false],
            range: {
                min: 0,
                max: 200
            },
            format: {
                to: function (value: any) {
                    return value;
                },
                from: function (value: any) {
                    return value;
                },
            },
        });

        const snapValues = [
            (ref.current as HTMLElement).querySelector(".js-value"),
        ];

        refSlider.current['noUiSlider'].on("update", function (values, handle) {
            const newDistance = values[0] ?? 0
            snapValues[0].innerHTML = newDistance;
            if (distance === (values[0] ?? 0)) {
                return
            }
            setDistance(newDistance)
        });
    }, [])

    useEffect(() => {
        refSlider.current['noUiSlider'].set(distance)
    }, [distance])


    return (
        <div ref={ref} className='slider-geo'>
            <div className="d-flex justify-between mb-20">
                <div className="text-16 text-primary">
                    Dans un rayon de : <span className="js-value" /> km
                </div>
            </div>
            <div className="px-5">
                <div className="js-slider" />
            </div>
        </div>
    )
}