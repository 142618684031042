import { useState, useEffect } from "react"
import { getOffsetTop } from "@/services/dom"

export function useSearchBar() {
    useEffect(() => {

        var lastScroll = window.scrollY;

        const onScroll = () => {

            if (document.querySelector('.navhead .mainSearch') === null) return
            if (document.querySelector('.navscroll .mainSearch') === null) return 

            if (
                window.scrollY > getOffsetTop(document.querySelector('.navhead .mainSearch')) - ((document.querySelector('header') as HTMLElement).offsetHeight - parseFloat(getComputedStyle(document.querySelector('header')).paddingBottom)) &&
                lastScroll < window.scrollY &&
                document.querySelector(".navscroll")?.classList.contains("d-none")
            ) {
                document.querySelector(".navscroll")?.classList.remove("d-none");
                if ((document.querySelector(".navhead input[type=\"text\"]")) === document.activeElement) {
                    const caretPosition = ((document.querySelector(".navhead input[type=\"text\"]")) as HTMLInputElement).selectionStart
                    const input = document.querySelector(".navscroll input[type=\"text\"]") as HTMLInputElement
                    input.focus()
                    input.setSelectionRange(caretPosition, caretPosition)
                }                    
            }
            if (
                //getOffsetTop(document.querySelector('.navscroll .mainSearch')) <= getOffsetTop(document.querySelector('.navhead .mainSearch')) &&
                window.scrollY < getOffsetTop(document.querySelector('.navhead .mainSearch')) - ((document.querySelector('header') as HTMLElement).offsetHeight - parseFloat(getComputedStyle(document.querySelector('header')).paddingBottom)) &&
                lastScroll > window.scrollY &&
                !document.querySelector(".navscroll")?.classList.contains("d-none")
            ) {
                document.querySelector(".navscroll")?.classList.add("d-none");
                if ((document.querySelector(".navscroll input[type=\"text\"]")) === document.activeElement) {
                    const caretPosition = ((document.querySelector(".navscroll input[type=\"text\"]")) as HTMLInputElement).selectionStart
                    const input = document.querySelector(".navhead input[type=\"text\"]") as HTMLInputElement
                    input.focus()
                    input.setSelectionRange(caretPosition, caretPosition)
                }
            }
            lastScroll = window.scrollY;
        }

        onScroll()
        addEventListener("scroll", onScroll);

        return () => {
            removeEventListener("scroll", onScroll)
        }
    }, [])

    useEffect(() => {
        document.querySelector('main')?.classList.add('has-sticky')

        return () => {
            document.querySelector('main')?.classList.remove('has-sticky')
        }
    }, [])

    return true
}