export function getOffsetTop(elem: Element)
{
    var offsetTop = 0;
    do {
      if ( !isNaN( (elem as HTMLElement).offsetTop ) )
      {
          offsetTop += (elem as HTMLElement).offsetTop;
      }
    } while( elem = (elem as HTMLElement).offsetParent );
    return offsetTop;
}

export function getOffsetBottom(elem: Element)
{
    return getOffsetTop(elem) + (elem as HTMLElement).offsetHeight;
}