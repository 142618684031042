'use client'

import React, { useState, useEffect } from "react"
import Dropdown from "@/components/form/Dropdown"
import { convertSearchParamToArray } from "@/services/search"
import { levels } from "@/services/school"
import Area from "@/components/form/Area"
import { useSearchBar } from "@/components/search/useSearchBar"

type SearchBarProps = {
    search?: any,
    setSearch?: Function,
    handleSearch?: Function
}

export default function SearchBar({
    search,
    setSearch,
    handleSearch
}: SearchBarProps) {        

    useSearchBar()

    return (
        <>
            <div id="thematique" className="mainSearch bg-white px-10 py-10 lg:px-20 lg:pt-5 lg:pb-20 mt-20">
                <div className="button-grid items-center">
                    <div className="px-30 lg:py-20 lg:px-0">
                        <div>
                            <h4 className="text-15 fw-500 ls-2 lh-16">Rechercher</h4>
                            <div className="text-15 text-light-1 ls-2 lh-16">
                                <input
                                    autoComplete="off"
                                    type="text"
                                    placeholder="Un lieu, un thème, ..."
                                    value={search['query'] ?? ''}
                                    onChange={(e) => {
                                        const {
                                            query,
                                            ...otherSearchParams
                                        } = search
                                        setSearch({
                                            ...otherSearchParams,                                        
                                            ...(e.target.value && { query: e.target.value})                                        
                                        })  
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key !== 'Enter') return
                                        handleSearch()
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="searchMenu px-30 lg:py-20 lg:px-0">
                        <Dropdown
                            title="Niveau"
                            multiple={false}
                            search={false}
                            nullable={true}
                            value={convertSearchParamToArray(search['levels'] ?? "")}
                            options={Object.fromEntries(Object.entries(levels).map(([keyLevel, level]) => {
                                return [
                                    keyLevel, level.name
                                ]
                            }))}
                            placeholder="Tous les niveaux"                            
                            onChange={(value) => {
                                const {
                                    levels,
                                    ...otherSearchParams
                                } = search
                                setSearch({
                                    ...otherSearchParams,                                        
                                    ...(value && { levels: value })
                                })
                            }}
                            withLabel={true}
                        />
                    </div>
                    <div className="searchMenu px-30 lg:py-20 lg:px-0">
                        <div>
                            {/*<Dropdown
                                title="Localisation"
                                multiple={false}
                                search={false}
                                value={search['season'] && search['year'] ? search['season'] + "-" + search['year'] : ""}
                                options={{
                                    'printemps-2025': 'Printemps 2025',
                                    'ete-2025': 'Eté 2025',
                                    'toussaint-2025': 'Toussaint 2025'
                                }}
                                placeholder="Toutes les villes"
                                onChange={(value) => {                                                                        
                                    const {
                                        year,
                                        season,
                                        ...otherSearchParams
                                    } = search
                                    setSearch({
                                        ...otherSearchParams,                                        
                                        ...(value.split('-').length === 2 && { season: value.split('-')[0], year: value.split('-')[1] })                                        
                                    })                                    
                                }}
                                withLabel={true}
                            />*/}
                            <Area
                                value={convertSearchParamToArray((typeof search['location'] === 'string' ? search['location'] : (search['location'] as Array<any>)?.join(',')) ?? "")}
                                onChange={(value) => {
                                    console.log('onChange', value)
                                    const {
                                        location,
                                        ...otherSearchParams
                                    } = search
                                    setSearch({
                                        ...otherSearchParams,                                        
                                        ...(value && { location: value })
                                    })
                                }}
                            />
                        </div>
                    </div>                    
                    <div className="button-item">
                        <button className="mainSearch__submit button col-12 rounded-full bg-primary text-white" onClick={() => {
                            handleSearch()
                        }}>
                            <span className="lancerrecherche mr-10 d-lg-none">Lancer la recherche</span>
                            <i className="icon-search text-20" />
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}